import axios from '@/api/axios'

class TelegramConfirmService {
  async confirmTelegram(id: string): Promise<any> {
    try {
      return await axios.post('/api/b2b/v1/messengers/tg/sync', { id })
    } catch (error) {
      return error.response
    }
  }
}

export default new TelegramConfirmService()
