























































import { Component, Vue } from 'vue-property-decorator'
import TelegramConfirmService from './TelegramConfirmService'

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Modal from '@/components/Modal.vue'

@Component({
  name: 'TelegramConfirm',
  components: {
    Content,
    BaseTitle,
    BaseButton,
    Modal,
  },
  props: ['id'],
})
export default class TelegramConfirm extends Vue {
  showSuccessModal = false
  showErrorModal = false

  async returnHome(): Promise<void> {
    await this.$router.push({ name: 'MyRooms' })
  }

  async created(): Promise<void> {
    if (this.$props.id) {
      const result = await TelegramConfirmService.confirmTelegram(
        this.$props.id
      )
      console.log(result)
      if (result.status === 200) {
        this.showSuccessModal = true
      } else {
        this.showErrorModal = true
      }
    }
  }
}
